import React, { useState, useEffect, useCallback, useMemo } from "react";
import Results from "./Results";
import printIcon from "../img/print.svg";
import closeModal from "../img/close-small.svg";
import { getUrl } from "./apiUrl";
import { motion } from "framer-motion"; // Используем для анимаций

import {
  smitPlasterboardPLUK,
  smitPlasterboardUK,
  smitWallMaterial,
} from "../data/smitMaterialData";
import { knaufPlasterboard, knaufWallMaterial } from "../data/knaufMaterialData";

function ResultsStep({
  wallArea,
  tapeLength,
  plasterboardArea,
  selectedSeamType,
  selectedManufacturer,
  selectedWallSurface,
}) {
  const [calculatedMaterials, setCalculatedMaterials] = useState([]);
  const [calculatedMaterialsRoof, setCalculatedMaterialsRoof] = useState([]);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [prices, setPrices] = useState([]);

  const [isCalled, setIsCalled] = useState(false);

  const getMaterialsBase = useCallback(() => {
    let materialsToDisplay = [];

    if (selectedManufacturer === "Smit") {
      let wallSurfaceMaterials = [];

      // если у нас гипсокартон, тогда шов влияет на что-то
      if (selectedWallSurface === "plasterboard") {
        wallSurfaceMaterials =
          selectedSeamType === "Шов УК"
            ? smitPlasterboardUK
            : smitPlasterboardPLUK;
      } else {
        wallSurfaceMaterials = smitWallMaterial;
      }

      // Добавляем все остальные материалы
      materialsToDisplay = [...wallSurfaceMaterials];
    }
    
    if (selectedManufacturer === "Knauf") {
      let wallSurfaceMaterials = [];

      // если у нас гипсокартон, тогда шов влияет на что-то
      if (selectedWallSurface === "plasterboard") {
        wallSurfaceMaterials =
          selectedSeamType === "Шов УК"
            ? knaufPlasterboard
            : knaufPlasterboard;
      } else {
        wallSurfaceMaterials = knaufWallMaterial;
      }

      // Добавляем все остальные материалы
      materialsToDisplay = [...wallSurfaceMaterials];
    }

    return materialsToDisplay;
  }, [selectedManufacturer, selectedSeamType, selectedWallSurface]);

  const getMaterialsBaseRoof = useCallback(() => {
    // Инициализируем список материалов с теми, что соответствуют выбранному типу шва
    let materialsToDisplay = [];

    if (selectedManufacturer === "Smit") {
      // Добавляем все остальные материалы
      materialsToDisplay =
        selectedSeamType === "Шов УК"
          ? smitPlasterboardUK
          : smitPlasterboardPLUK;
    }

    return materialsToDisplay;
  }, [selectedManufacturer, selectedSeamType]);

  const calculateMaterials = (materials, area) => {
    return materials.map((material) => {
      let requiredAmount;

      // Если есть формула, вычисляем на ее основе
      if (material.formula) {
        try {
          // Извлекаем правую часть формулы после знака '='
          const formulaBody = material.formula.split("=")[1].trim();

          // Заменяем все вхождения 'm2' на значение площади (area)
          const formulaWithArea = formulaBody.replace(/m2/g, area);

          // Вычисляем значение формулы с помощью new Function
          requiredAmount = new Function(`return ${formulaWithArea}`)();
        } catch (error) {
          console.error(
            `Ошибка при вычислении формулы для материала ${material.id}:`,
            error
          );
          requiredAmount = area * material.consumptionPerSquareMeter; // fallback если ошибка
        }
      } else {
        // Если формулы нет, используем стандартный расчет
        requiredAmount = area * material.consumptionPerSquareMeter;
      }

      const packagingValue = parseFloat(material.packaging);

      return {
        itemId: material.id,
        name: material.name || "Unknown",
        quantity: requiredAmount.toFixed(1), // Количество материала (в кг или других единицах)
        units:
          packagingValue > 0
            ? (requiredAmount / packagingValue).toFixed(1) // Количество упаковок
            : "0",
        icon: material.icon,
        unit: material.unit,
        packaging: material.packaging,
        consumptionPerSquareMeter: material.consumptionPerSquareMeter,
      };
    });
  };

  const calculateResults = useCallback(() => {
    if (wallArea && tapeLength) {
      const materialsBase = getMaterialsBase();

      setCalculatedMaterials(calculateMaterials(materialsBase, wallArea));
    }
  }, [wallArea, tapeLength, getMaterialsBase]);

  useEffect(() => {
    calculateResults();
  }, [calculateResults]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("user")).id;
    if (!selectedManufacturer || !userId) return;

    const fetchPrices = async () => {
      try {
        const apiUrl = getUrl();
        const response = await fetch(
          `${apiUrl}/api/prices?manufacturer=${selectedManufacturer}&userId=${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();
        setPrices(data);
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchPrices();
  }, [selectedManufacturer]);

  useEffect(() => {
    if (prices.length > 0) {
      setCalculatedMaterials((prevMaterials) =>
        prevMaterials.map((material) => {
          const foundPrice = prices.find(
            (price) => price.itemId === material.itemId
          );
          return {
            ...material,
            price: foundPrice ? foundPrice.price : 0,
          };
        })
      );

      setCalculatedMaterialsRoof((prevMaterials) =>
        prevMaterials.map((material) => {
          const foundPrice = prices.find(
            (price) => price.itemId === material.itemId
          );
          return {
            ...material,
            price: foundPrice ? foundPrice.price : 0,
          };
        })
      );
    }
  }, [prices]);

  const handleOpenCard = () => {
    console.log("JJJJJJJJJJ", calculatedMaterials)
    // Check if there are any missing prices
    const hasMissingPrices = calculatedMaterials.some(
      (material) => material.price === 0
    );

    if (hasMissingPrices) {
      setIsWarningVisible(true); // Show warning card
    } else {
      setIsCardVisible(true); // Show estimate card
    }
  };

  const handlePrint = () => {
    const printContents = document.querySelector(
      ".total-card-content"
    ).outerHTML;
    const originalContents = document.body.innerHTML;

    // Временно заменяем содержимое body на содержимое модалки
    document.body.innerHTML = printContents;

    // Запускаем команду печати
    window.print();

    // Восстанавливаем исходное содержимое страницы
    document.body.innerHTML = originalContents;

    // Опционально перезагружаем скрипты или восстанавливаем состояние страницы (если нужно)
    window.location.reload(); // Перезагрузка страницы для восстановления всех событий и элементов
  };

  const handleCloseCard = () => {
    setIsCardVisible(false);
  };

  const handleCloseWarning = () => {
    setIsWarningVisible(false); // Close the warning card
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("total-card-overlay")) {
      handleCloseCard();
    }
  };

  const getTotalAmount = () => {
    const totalAmountWall = calculatedMaterials.reduce(
      (acc, material) => acc + material.price * material.units,
      0
    );

    const totalAmount = totalAmountWall;
    return totalAmount;
  };

  const getWallSurfaceLabel = (option) => {
    if (option === "plaster") {
      return "Штукатурка";
    } else if (option === "plasterboard") {
      return "Гипсокартон";
    }
    return "";
  };

  const getManufacterLabel = (option) => {
    if (option === "Smit") {
      return "СМиТ";
    } else if (option === "Knauf") {
      return "Knauf";
    }
    return "";
  };

  const getSeamLabel = (option) => {
    if (option === "Шов УК") {
      return "Шов УК";
    } else if (option === "Шов ПЛУК") {
      return "Шов ПЛУК";
    }
    return "-";
  }

  // Функция для объединения материалов с ценами
  const mergePricesWithMaterials = useCallback((materials, prices) => {
    return materials.map((material) => {
      const foundPrice = prices.find(
        (price) => price.itemId === material.itemId
      );
      const price = foundPrice ? parseFloat(foundPrice.price) : 0;
      const quantity = parseFloat(material.quantity);
      const totalPrice = (price * quantity).toFixed(2);

      return {
        ...material,
        price,
        totalPrice,
      };
    });
  }, []);

  // Функция отправки данных на сервер
  const sendCalculationToServer = useCallback(async () => {
    if (isCalled) return;

    if (calculatedMaterials.length > 0 && calculatedMaterialsRoof.length > 0) {
      setIsCalled(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user ? user.id : null;

      if (!userId) {
        return;
      }

      try {
        const apiUrl = getUrl();
        const priceResponse = await fetch(
          `${apiUrl}/api/prices?userId=${userId}&manufacturer=${selectedManufacturer}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!priceResponse.ok) {
          throw new Error(`Error fetching prices: ${priceResponse.statusText}`);
        }

        const prices = await priceResponse.json();

        // Объединяем материалы с ценами
        const mergedMaterials = mergePricesWithMaterials(
          calculatedMaterials,
          prices
        );
        const mergedRoofMaterials = mergePricesWithMaterials(
          calculatedMaterialsRoof,
          prices
        );
        // Подготовка данных для отправки
        const dataToSend = {
          userId,
          wallArea,
          selectedSeamType,
          selectedManufacturer,
          selectedWallSurface,
          calculatedMaterials: mergedMaterials.map((item) => ({
            itemId: item.itemId,
            name: item.name || "Unknown",
            quantity: item.quantity,
            units: item.units,
            unit: item.unit,
            price: item.price || 0,
            totalPrice: item.totalPrice || 0,
          })),
          calculatedRoofMaterials: mergedRoofMaterials.map((item) => ({
            itemId: item.itemId,
            name: item.name || "Unknown",
            quantity: item.quantity,
            units: item.units,
            unit: item.unit,
            price: item.price || 0,
            totalPrice: item.totalPrice || 0,
          })),
        };

        console.log("Данные, отправляемые на сервер:", dataToSend);

        // Отправляем расчет на сервер
        const response = await fetch(`${apiUrl}/api/calculate/save-history`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(dataToSend),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
      } catch (error) {
        console.error("Error saving calculation:", error);
      }
    } else {
    }
  }, [calculateMaterials, calculatedMaterialsRoof]);

  useEffect(() => {
    sendCalculationToServer();
  }, [sendCalculationToServer]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="results-container custom-scrollbar">
      <div className="summary-card">
        <div className="summary-actions">
          <h3>Расчет</h3>
          <div className="summary-action-btns">
            <button className="summary-action-button" onClick={handleOpenModal}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 19C14.95 19 19 14.95 19 10C19 5.05 14.95 1 10 1C5.05 1 1 5.05 1 10C1 14.95 5.05 19 10 19Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.27488 12.9529C9.27488 12.4552 9.36967 12.0032 9.55924 11.597C9.74882 11.1805 9.9763 10.8301 10.2417 10.5457C10.5071 10.2613 10.7725 9.98707 11.0379 9.72299C11.3033 9.45891 11.5308 9.16944 11.7204 8.85457C11.91 8.52955 12.0047 8.18421 12.0047 7.81856C12.0047 7.22945 11.8152 6.78763 11.436 6.49307C11.0664 6.18837 10.5877 6.03601 10 6.03601C8.83412 6.03601 8.11374 6.54894 7.83886 7.57479L7 7.07202C7.21801 6.39151 7.59716 5.87858 8.13744 5.53324C8.6872 5.17775 9.30806 5 10 5C10.8341 5 11.5403 5.24885 12.1185 5.74654C12.7062 6.23407 13 6.92475 13 7.81856C13 8.25531 12.9052 8.66159 12.7156 9.0374C12.5261 9.4132 12.2986 9.74331 12.0332 10.0277C11.7678 10.3019 11.5024 10.5762 11.237 10.8504C10.9716 11.1247 10.7441 11.4446 10.5545 11.8102C10.3649 12.1657 10.2701 12.5466 10.2701 12.9529H9.27488ZM10.327 15.7562C10.1754 15.9187 9.99052 16 9.77251 16C9.5545 16 9.36967 15.9187 9.21801 15.7562C9.06635 15.5937 8.99052 15.3957 8.99052 15.162C8.99052 14.9284 9.06635 14.7304 9.21801 14.5679C9.36967 14.4054 9.5545 14.3241 9.77251 14.3241C9.99052 14.3241 10.1754 14.4054 10.327 14.5679C10.4787 14.7304 10.5545 14.9284 10.5545 15.162C10.5545 15.3957 10.4787 15.5937 10.327 15.7562Z"
                  fill="white"
                />
              </svg>
              <label className="summary-label">Что такое Q1-Q4?</label>
            </button>
            <button className="summary-action-button" onClick={handleOpenCard}>
              <img
                className="summary-icon"
                src={printIcon}
                alt="Рассчитать смету"
              />
              <label className="summary-label">Рассчитать смету</label>
            </button>
          </div>
        </div>
        <div className="summary-card-total">
          <div className="summary-item">
            <span className="summary-title">Производитель</span>
            <span className="summary-value">
              {getManufacterLabel(selectedManufacturer)}
            </span>
          </div>
          <div className="summary-item">
            <span className="summary-title">Тип шва</span>
            <span className="summary-value">
            {getSeamLabel(selectedSeamType)}</span>
          </div>
          <div className="summary-item">
            <span className="summary-title">Площадь поверхности</span>
            <span className="summary-value">{wallArea} м²</span>
          </div>
          <div className="summary-item">
            <span className="summary-title">Покрытие стен</span>
            <span className="summary-value">
              {getWallSurfaceLabel(selectedWallSurface)}
            </span>
          </div>
          {/* <div className="summary-item">
            <span className="summary-title">Площадь потолка</span>
            <span className="summary-value">{plasterboardArea} м²</span>
          </div> */}
        </div>
      </div>

      {isModalVisible && (
        <div
          className="q-modal-overlay"
          onClick={handleCloseModal}
        >
          <motion.div
            className="q-modal-content"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="q-modal-body">
              <h4>Что такое Q1-Q4?</h4>
              <ul>
                <li>
                  <strong>
                    Уровень Q1 соответствует поверхностям без декоративных
                    требований
                  </strong>{" "}
                  Отделка гипсокартона уровня Q1 является достаточной, если к
                  поверхности не предъявляются декоративные требования.
                  Поверхности уровня качества Q1 могут быть покрыты плиткой,
                  листовым материалом или толстослойной штукатуркой.
                </li>
                <li>
                  <strong>
                    Уровень Q2 соответствует поверхностям с минимальной степенью
                    гладкости
                  </strong>{" "}
                  Поверхность гипсокартона со степенью отделки Q2 подходит
                  для структурированных обоев, структурированной краски и
                  покрытий, наносимых вручную с помощью валиков из овчины или
                  структурированных валиков, а также толстых финишных покрытий с
                  размером частиц более 1 мм.
                </li>
                <li>
                  <strong>
                    Уровень Q3 соответствует поверхностям с повышенными
                    требованиями к визуальному качеству
                  </strong>{" "}
                  Поверхность гипсокартона со степенью отделки Q3 подходит для
                  тонких структурированных обоев и лакокрасочных покрытий, а
                  также финишных покрытий с размером частиц менее 1 мм.
                </li>
                <li>
                  <strong>
                    Уровень Q4 соответствует поверхностям, подготовленным
                    согласно самым высоким декоративным стандартам
                  </strong>{" "}
                  Отделка уровня Q4 соответствует самым высоким требованиям к
                  визуальному качеству поверхности. Отделка такого качества
                  позволяет декорировать поверхность глянцевыми обоями, красками
                  или лаками, а также мраморной штукатуркой и другими
                  аналогичными отделочными материалами.
                </li>
              </ul>
            </div>
          </motion.div>
        </div>
      )}

      <Results
        title="Материалы"
        items={calculatedMaterials}
        count={calculatedMaterials.length}
      />
      {/* <Results
        title="Лента"
        items={calculatedTapes}
        count={calculatedTapes.length}
      /> */}
      {/* <Results
        title="Потолок"
        items={calculatedMaterialsRoof}
        count={calculatedMaterialsRoof.length}
      /> */}

      {isCardVisible && (
        <div
          className="total-card-overlay"
          onClick={handleOutsideClick} // Handle clicks outside the card
        >
          <div className="total-card-content">
            <button className="total-card-print" onClick={handlePrint}>
              <img
                className="total-card-icon"
                src={printIcon}
                alt="Рассчитать смету"
              />
            </button>
            <h2 className="total-card-title">Смета</h2>

            <div className="total-results-content">
              <div className="total-results-header">
                <div className="total-details">
                  <div className="total-detail">
                    <span className="total-item-name">Название</span>
                  </div>
                  <div className="total-detail-right">
                    <div className="total-detail">
                      <span className="total-title">Цена шт.</span>
                    </div>
                    <div className="total-detail">
                      <span className="total-title">Кол-во упаковок</span>
                    </div>
                    <div className="total-detail">
                      <span className="total-title">Сумма</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="total-items-list">
                {calculatedMaterials.map((material, index) => (
                  <div className="total-item" key={index}>
                    <div className="total-details">
                      <div className="total-detail">
                        <span className="total-item-name">{material.name}</span>
                      </div>
                      <div className="total-detail-right">
                        <div className="total-detail">
                          <span className="total-value">
                            {material.price?.toLocaleString()} Р
                          </span>
                        </div>
                        <div className="total-detail">
                          <span className="total-value">
                            {material.units} шт.
                          </span>
                        </div>
                        <div className="total-detail">
                          <span className="total-value">
                            {(
                              material.price * material.units
                            )?.toLocaleString()}{" "}
                            Р
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* Потолок
                {calculatedMaterialsRoof.map((material, index) => (
                  <div className="total-item" key={index}>
                    <div className="total-details">
                      <div className="total-detail">
                        <span className="total-item-name">{material.name}</span>
                      </div>
                      <div className="total-detail-right">
                        <div className="total-detail">
                          <span className="total-value">
                            {material.price?.toLocaleString()} Р
                          </span>
                        </div>
                        <div className="total-detail">
                          <span className="total-value">
                            {material.quantity} шт.
                          </span>
                        </div>
                        <div className="total-detail">
                          <span className="total-value">
                            {(
                              material.price * material.quantity
                            )?.toLocaleString()}{" "}
                            Р
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}
              </div>
              <div className="total-summary">
                <strong>Итого: </strong>
                <span>{getTotalAmount().toLocaleString()} Р</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {isWarningVisible && (
        <div className="warning-overlay" onClick={handleCloseWarning}>
          <div className="warning-card" onClick={(e) => e.stopPropagation()}>
            <div className="warning-card-header">
              <h2>Упс!</h2>
              <button className="warning-card-btn" onClick={handleCloseWarning}>
                <img
                  className="warning-card-icon"
                  src={closeModal}
                  alt="Закрыть"
                />
              </button>
            </div>
            <p>
              Прежде чем получить смету, необходимо заполнить информацию в
              разделе “Мои цены”.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResultsStep;
